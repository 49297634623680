<template>
  <div class="staffInfo" role="button">
    <i
      class="iconfont icon-chahao"
      v-show="showCloseBtn"
      @click="clickCloseIcon"
    ></i>
    <div class="title x-title" tabindex="0">
      <div class="staff">
        <v-avatar
          class="avator"
          size="77"
          v-if="data.image != 'avatar.jpg' && (data.image || data.photo)"
        >
          <!-- <img
            :src="avatorImg"
            :alt="data.name"
          > -->
          <newImg :fileUrl="avatorImg"></newImg>
          <!--<img-->
          <!--  :src="'files/'+data.image"-->
          <!--  :alt="data.name"-->
          <!--&gt;-->
        </v-avatar>
        <div v-else>
          <v-avatar
            color="#707070"
            class="avator"
            size="77"
            tabindex="0"
            v-if="data.firstName"
            style="color: #fff"
          >
            <label class="white--text" style="font-size: 24px">
              {{
                data.firstName[0].toUpperCase() +
                " " +
                data.lastName[0].toUpperCase()
              }}
            </label>
          </v-avatar>
          <v-avatar color="#707070" class="avator" v-else tabindex="0">
            <label class="white--text" style="font-size: 24px">
              {{ data.name[0].toUpperCase() }}
            </label>
          </v-avatar>
        </div>
        <div class="right">
          <div class="name" tabindex="0">
            <label>{{ data.name }}</label>
            <span
              class="triangle-up"
              :class="`${
                data.employeeGroupId == '1' ? 'triangle-color-ff0000' : ''
              }
              ${data.employeeGroupId == '2' ? 'triangle-color-a020f0' : ''}
              ${
                data.employeeGroupId == '3' || data.employeeGroupId == '8'
                  ? 'triangle-color-1473e6'
                  : ''
              }
              ${
                data.employeeGroupId == '4' || data.employeeGroupId == '5'
                  ? 'triangle-color-fd9641'
                  : ''
              }`"
              v-if="data.employeeGroupId in ['1', '2', '3', '4', '5', '8']"
            ></span>
          </div>
          <div tabindex="0">
            <label> {{ data.title }}</label>
          </div>
          <div tabindex="0">
            <label>{{ data.company }}</label>
          </div>
        </div>
      </div>
      <!-- <div class="deskShare" v-if="showDeskShareBtns && client=='Lenovo Corporate'">
        <v-btn rounded class="x-btn" @click="seeBookings">See bookings</v-btn>
        <v-btn rounded class="x-btn" v-if="data.locationShareStatus=='accept'&&showUnshare" @click="unshare">Unshare
        </v-btn>
      </div> -->
      <div class="deskShare">
        <div>
          <div
            class="addToFavorites"
            @click="updateFavorites()"
            v-if="showFavoriteFunction == true"
          >
            <img
              src="@/assets/images/map/star.png"
              style="width: 20px"
              v-if="data.isFavorite == false"
            />
            <img
              src="@/assets/images/map/star@2x.png"
              style="width: 20px"
              v-if="data.isFavorite == true"
            />
            <p v-if="data.isFavorite == false">Add to Favorites</p>
            <p v-if="data.isFavorite == true">Remove from Favorites</p>
          </div>
          <div
            class="seeingBooking"
            v-if="showDeskShareBtns && client == 'Lenovo Corporate'"
          >
            <v-btn rounded class="x-btn" @click="seeBookings"
              >See bookings</v-btn
            >
            <v-btn
              rounded
              class="x-btn"
              v-if="data.locationShareStatus == 'accept' && showUnshare"
              @click="unshare"
              >Unshare
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <div class="rightContent-content" tabindex="0">
      <div class="staffContent">
        <div class="datas">
          <div
            class="oneDetail"
            v-if="data.department && data.department != ''"
          >
            <span class="oneDetail-title" tabindex="0">Department:</span>
            <span tabindex="0">{{ data.department }}</span>
          </div>
          <div class="oneDetail" v-if="data.unit && data.unit != ''">
            <span class="oneDetail-title" tabindex="0">BU:</span>
            <span tabindex="0">{{ data.unit }}</span>
          </div>
          <div class="oneDetail">
            <span class="oneDetail-title" tabindex="0">Email:</span>
            <!--<span style="color:#EE2C54" tabindex="102">{{ data.email }}</span>-->
            <a href="mailto:data.email" style="color: #0000cc" tabindex="0">{{
              data.email
            }}</a>
          </div>
          <div
            class="oneDetail"
            v-if="data.isPhoneShown && data.phoneNumber !== ''"
          >
            <span class="oneDetail-title" tabindex="0">Phone:</span>
            <span style="color: #0000cc" tabindex="0">{{
              data.phoneNumber
            }}</span>
          </div>
          <div class="oneDetail" v-if="data.location !== ''">
            <span class="oneDetail-title" tabindex="0">Location:</span>
            <span tabindex="0">{{ data.location }}</span>
          </div>
          <div class="oneDetail" v-if="data.building !== ''">
            <span class="oneDetail-title" tabindex="0">Building:</span>
            <span tabindex="0">{{ data.building }}</span>
          </div>
          <div class="oneDetail" v-if="data.cubical !== ''">
            <span class="oneDetail-title" tabindex="0">Desk:</span>
            <span tabindex="0">{{ data.cubical }}</span>
          </div>
          <div class="oneDetail" v-if="data.timezone && data.timezone != ''">
            <span class="oneDetail-title" tabindex="0">Time Zone:</span>
            <span tabindex="0">{{ data.timezone }}</span>
          </div>
        </div>

        <div class="staffContent-btn" v-show="false">
          <v-btn
            rounded
            class="x-btn"
            @click="clickOrganization"
            v-if="
              data.orgStructure &&
              (data.orgStructure.reportFrom.length > 0 ||
                data.orgStructure.reportTo.length > 0)
            "
          >
            Organizational Structure
          </v-btn>
        </div>
      </div>

      <div
        class="attendeeItem"
        v-if="showAttributesFunction && attributes.length > 0"
      >
        <hr class="x-hr" />
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-row>
            <v-col
              cols="3"
              md="2"
              sm="1"
              xs="1"
              style="text-align: center"
              @click="showAttributes"
            >
              <!-- <img
                  src="@/assets/images/map/checkmark@2x.png" 
                  class="hook"
                > -->
              <div
                :class="{ 'gd-down-180': isShowAttributes }"
                class="gd-down-detault"
              >
                <i class="iconfont icon-xiangxiajiantou1 gd-down"></i>
              </div>
            </v-col>
            <v-col cols="9" md="10" sm="11" xs="11" @click="showAttributes">
              <p>Specialties</p>
              <div class="attributeContent" v-if="isShowAttributes">
                <span
                  class="attributesSpan"
                  v-for="i in attributes"
                  @click.stop="attritutesSearch(i)"
                  >{{ i }}</span
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <div class="attendeeItem">
        <hr class="x-hr" />
        <v-col
          cols="12"
          md="12"
          sm="12"
          xs="12"
          v-if="
            (data.employeeGroupName && data.employeeGroupName != '') ||
            (data.mgmtLevel && data.mgmtLevel != '') ||
            (data.hireDate && data.hireDate != '') ||
            (data.costCenterId && data.costCenterId != '')
          "
        >
          <v-row>
            <v-col
              cols="3"
              md="2"
              sm="1"
              xs="1"
              style="text-align: center"
              @click="additionalIsShow"
            >
              <!-- <img
                  src="@/assets/images/map/checkmark@2x.png" 
                  class="hook"
                > -->
              <div
                :class="{ 'gd-down-180': isAdditional }"
                class="gd-down-detault"
              >
                <i class="iconfont icon-xiangxiajiantou1 gd-down"></i>
              </div>
            </v-col>
            <v-col cols="9" md="10" sm="11" xs="11" @click="additionalIsShow">
              <p>Additional Info</p>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12">
              <div class="staffContent">
                <div class="datas" style="padding-top: 0">
                  <div
                    class="oneDetail"
                    v-if="
                      data.employeeGroupName != '' && data.employeeGroupName
                    "
                  >
                    <span class="oneDetail-title" tabindex="0">Work Type:</span>
                    <span tabindex="0"
                      >{{ data.employeeGroupName }}
                      {{ data.employeeGroupId }}</span
                    >
                    <span
                      class="triangle-up"
                      :class="`${
                        data.employeeGroupId == '1'
                          ? 'triangle-color-ff0000'
                          : ''
                      }
                      ${
                        data.employeeGroupId == '2'
                          ? 'triangle-color-a020f0'
                          : ''
                      }
                      ${
                        data.employeeGroupId == '3' ||
                        data.employeeGroupId == '8'
                          ? 'triangle-color-1473e6'
                          : ''
                      }
                      ${
                        data.employeeGroupId == '4' ||
                        data.employeeGroupId == '5'
                          ? 'triangle-color-fd9641'
                          : ''
                      }`"
                      v-if="
                        data.employeeGroupId in ['1', '2', '3', '4', '5', '8']
                      "
                    ></span>
                  </div>
                  <div
                    class="oneDetail"
                    v-if="data.mgmtLevel != '' && data.mgmtLevel != ''"
                  >
                    <span class="oneDetail-title" tabindex="0">Level:</span>
                    <span tabindex="0">{{ data.mgmtLevel }}</span>
                  </div>
                  <div
                    class="oneDetail"
                    v-if="data.hireDate != '' && data.hireDate != ''"
                  >
                    <span class="oneDetail-title" tabindex="0">Hire Date:</span>
                    <span tabindex="0">{{ data.hireDate }}</span>
                  </div>
                  <div
                    class="oneDetail"
                    v-if="data.costCenterId != '' && data.costCenterId != ''"
                  >
                    <span class="oneDetail-title" tabindex="0"
                      >Cost Center:</span
                    >
                    <span tabindex="0">{{ data.costCenterId }}</span>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>

      <div
        class="attendeeItem"
        v-show="
          data.orgStructure &&
          (data.orgStructure.reportFrom.length > 0 ||
            data.orgStructure.reportTo.length)
        "
      >
        <hr class="x-hr" />
        <v-col cols="12" md="12" sm="12" xs="12">
          <v-row>
            <v-col
              cols="3"
              md="2"
              sm="1"
              xs="1"
              style="text-align: center"
              @click="structureShow"
            >
              <!-- <img
                  src="@/assets/images/map/checkmark@2x.png" 
                  class="hook"
                > -->
              <div
                :class="{ 'gd-down-180': structureIsShow }"
                class="gd-down-detault"
              >
                <!-- <i class="iconfont icon-xiangxiajiantou1 gd-down"></i> -->
                <i class="iconfont icon-xiajiantou" style="font-size: 22px"></i>
              </div>
            </v-col>
            <v-col cols="9" md="10" sm="11" xs="11" @click="structureShow">
              <p>Org Structure</p>
            </v-col>
            <v-col cols="12" md="12" sm="12" xs="12" v-show="structureIsShow">
              <div
                class="leadership"
                v-if="
                  data.orgStructure && data.orgStructure.reportTo.length > 0
                "
              >
                <div class="x-title-gray">
                  {{ data.firstName.toUpperCase() }}&nbsp;REPORTS TO
                </div>
                <div class="x-content">
                  <div
                    v-for="item in data.orgStructure
                      ? data.orgStructure.reportTo
                        ? data.orgStructure.reportTo
                        : 0
                      : 0"
                  >
                    <staff
                      style="cursor: pointer"
                      :data="item"
                      @showStaffDetail="clickPerson(item)"
                    ></staff>
                  </div>
                </div>
                <!--至少5条数据才显示-->
                <p
                  @click="showMore('to')"
                  v-show="
                    client == 'adobe' &&
                    showMoreTo &&
                    data.orgStructure.reportTo.length > 5
                  "
                  style="margin-left: 40px; cursor: pointer; font-size: 15px"
                >
                  show more
                </p>
              </div>
              <div
                class="subordinate"
                v-if="
                  data.orgStructure && data.orgStructure.reportFrom.length > 0
                "
              >
                <div class="x-title-gray">
                  REPORTING TO&nbsp;{{ data.firstName.toUpperCase() }}
                </div>
                <div class="x-content" v-show="showReportFromList">
                  <div
                    v-for="item in data.orgStructure
                      ? data.orgStructure.reportFrom
                      : 0"
                  >
                    <staff
                      style="cursor: pointer"
                      :data="item"
                      @showStaffDetail="clickPerson(item)"
                    ></staff>
                  </div>
                </div>
                <p
                  @click="showMore('from')"
                  v-show="
                    client == 'adobe' &&
                    showMoreFrom &&
                    data.orgStructure.reportFrom.length >= 5
                  "
                  style="margin-left: 40px; cursor: pointer; font-size: 15px"
                >
                  show more
                </p>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>

      <hr class="x-hr" />
      <p style="padding: 1rem; padding-bottom: 0; margin: 0; font-weight: bold">
        BIOGRAPHY
      </p>
      <div
        v-dompurify-html="data.bio"
        style="padding: 1rem; white-space: pre-line"
      ></div>

      <!--<div class="brief">-->
      <!--  {{ data.bio }}-->
      <!--</div>-->
    </div>
    <!--查看别的用户的deskList-->
    <v-dialog
      content-class="deskListDialog"
      v-model="showDeskList"
      max-width="350"
    >
      <v-card class="dialog-window ma-0 pa-0">
        <v-card-title>
          {{ data.firstName }}&nbsp;{{ data.lastName }}'s Bookings

          <v-spacer />

          <v-icon aria-label="Close" @click="showDeskList = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div
            class="staffInfoDeskList"
            v-for="item in deskList"
            @click="clickDeskList(item)"
          >
            <img
              src="@/assets/images/map/meet_calendar_deskGray.png"
              style="width: 32px; height: 20px; margin: 10px"
            />
            <div class="information">
              <p style="font-weight: bold">{{ item.date }}</p>
              <p>{{ item.start }} - {{ item.end }}</p>
              <p>Desk:{{ item.seat_name }}</p>
              <p>{{ item.campusAndFloorName }}</p>
            </div>
            <i class="iconfont icon-zuobiao"></i>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      content-class="userLocationDialog"
      id="userLocationDialog"
      v-model="showUserLocation"
      max-width="1200"
      style="overflow: hidden"
    >
      <v-card class="dialog-window ma-0 pa-0" height="90vh">
        <v-card-title>
          Location

          <v-spacer />

          <v-icon aria-label="Close" @click="showUserLocation = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <div id="floorMapBlock" ref="floorMapBlock">
            <canvas
              id="floorMap"
              ref="floorMap"
              :width="width"
              :height="height"
            ></canvas>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import staff from "@/views/dashboard/componentsHa/globalStaff";
import moment from "moment";
import newImg from "@/views/dashboard/componentsHa/img";

var canvas;
export default {
  name: "globalStaffInfo",
  props: {
    data: {
      type: Object,
    },
    showAttributesFunction: {
      type: Boolean,
      default: false,
    },
    showFavoriteFunction: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    staff,
    newImg,
  },
  watch: {
    data: {
      handler: function (newVal, oldVal) {
        console.log("newVal!!", newVal);
        this.showMoreFrom = true;
        this.showMoreTo = true;

        this.isShowAttributes = false;
        this.oldPerson = oldVal;
        console.log(this.oldPerson);
        if (newVal.image) {
          let src = newVal.image.toString();
          if (src.startsWith("data:image")) {
            this.avatorImg = newVal.image;
          } else {
            if (newVal.image != "") {
              this.avatorImg = "files/" + newVal.image;
            }
          }
        }
        this.getAttributes();
      },
      deep: true,
    },
    userLocationDialog: {
      handler: function (newVal, oldVal) {
        if (!newVal) {
          canvas.clear();
          canvas.renderAll();
        }
      },
      deep: true,
    },

    // data(newVal,oldVal){
    //   console.log("new!!!!",newVal)
    //   this.avatorImg = "files/"+newVal.image;
    // }
  },
  data() {
    return {
      showReportToList: true,
      showReportFromList: true,
      showMoreTo: true,
      showMoreFrom: true,
      firstInData: {}, //第一次进来时候的数据(针对Directory，右侧点击关闭按钮显示回左侧对应人员数据)
      showCloseBtn: false, //右上角关闭按钮
      oldPerson: {}, //上一次查询的人
      scaleSize: 0.3,
      client: "",
      showDeskShareBtns: false,
      showOrganazation: false,
      avatorImg: "",
      deskList: [],
      showDeskList: false, //展示别人预订的deskList 弹窗
      showUserLocation: false, //展示预订的位置
      height: 700, //canvas高度
      width: 400, //canvas宽度
      panning: false,
      canvasW: 0,
      canvasH: 0,
      imageW: 0,
      imageH: 0,
      showUnshare: true,
      attributes: [],
      isShowAttributes: false,
      structureIsShow: true,
      isAdditional: false,
      // timeoutZoom:null,
      // testHtml:"<img src='../ww.png' onerror='alert(1)'/> ",//测试XSS攻击
    };
  },
  methods: {
    additionalIsShow() {
      this.isAdditional = !this.isAdditional;
    },
    structureShow() {
      this.structureIsShow = !this.structureIsShow;
    },
    showMore(type) {
      this.$emit("showMore", type, this.data.id);
    },
    clickCloseIcon() {
      // console.log(this.firstInData,'9999')
      this.clickPerson(this.firstInData, false);
    },
    clickPerson(data, showCloseBtn = true) {
      this.$emit("changeShowList", data, showCloseBtn);
    },
    // 显示组织架构
    clickOrganization() {
      // console.log(this.data);
      this.showOrganazation = !this.showOrganazation;
      // if (window.gtag) {
      //   window.gtag('event', 'Directory', {
      //     event_category: 'Organization Structure Click',
      //     event_label: 'Organization Structure',
      //     send_to: localStorage.getItem("googleAnaId") || "",
      //     dimension1: localStorage.getItem("campusName") || ""
      //   })
      // }
      if (this.$gtag.event) {
        this.$gtag.event("Directory", {
          event_category: "Organization Structure Click",
          event_label: "Organization Structure",
          send_to: localStorage.getItem("googleAnaId") || "",
          dimension1: localStorage.getItem("campusName") || "",
        });
      }
    },
    requestLocationSharing() {
      this.bus.$emit("loading", true);
      this.$axios({
        method: "POST",
        url: " /m/locationSharing/request",
        data: {
          meetingId: localStorage.meetingIdMap,
          attendeeId: this.data.id,
        },
      })
        .then((res) => {
          this.bus.$emit("loading", false);
          console.log("res:", res);
          this.$message.success("Send request successfully");
        })
        .catch((err) => {
          this.bus.$emit("loading", false);
          console.log(err);
        });
    },
    formatListData(data) {
      let arr = [];
      data.map((item) => {
        let obj = {};
        obj.date = moment(item.start).format("dddd, MMM D");
        obj.start = moment(item.start).format("h:mm a");
        obj.end = moment(item.end).format("h:mm a");
        obj.campusAndFloorName = item.campusName + " " + item.floorName;
        obj.seat_name = item.seat_name;
        obj.resourceId = item.resourceId;
        obj.floorId = item.floorId;
        arr.push(obj);
      });

      return arr;
    },
    getUserSeats() {
      let d = new Date();
      d.setSeconds(0);
      let start = moment(d).startOf("day").unix();
      let end = moment(d).endOf("day").unix();
      this.bus.$emit("loading", true);
      this.$axios({
        method: "POST",
        url: "/m/v2/desk/getUserSeats",
        data: {
          meetingId: localStorage.meetingIdMap,
          start: start,
          end: end,
          attendeeId: this.data.id,
        },
      })
        .then((res) => {
          this.bus.$emit("loading", false);
          this.showUnshare = true;
          // console.log('res:', res);
          // this.deskList = res.data.data;
          this.deskList = this.formatListData(res.data.data);
          console.log("==== deskList=====", this.deskList);
          this.showDeskList = true;
        })
        .catch((err) => {
          this.bus.$emit("loading", false);
          console.log(err);
        });
    },
    seeBookings() {
      console.log(
        "==== locationShareStatus=====",
        this.data.locationShareStatus
      );
      if (this.data.locationShareStatus == "accept") {
        this.getUserSeats();
      } else {
        this.requestLocationSharing();
      }
    },
    unshare() {
      this.$axios({
        method: "POST",
        url: "/m/locationSharing/response",
        data: {
          attendeeId: this.data.id,
          status: "reject",
          meetingId: localStorage.meetingIdMap,
        },
      })
        .then((res) => {
          console.log("res:", res);
          this.$message.success("success");
          this.showUnshare = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    initCanvas(item, mapInfo, user) {
      console.log("==== canvas=====", item);
      console.log("==== user!!!!!!!!!!=====", user);
      let that = this;
      // 1. 实例化canvas 画布
      canvas = new fabric.Canvas("floorMap");
      // 2. 设置背景图片作为底图（这里导入图片使用require，不要 使用 '../../' 方式）
      canvas.width = this.$refs.floorMapBlock.offsetWidth;
      canvas.height = this.$refs.floorMapBlock.offsetHeight;
      // canvas.setHeight(this.$refs.mapClass.offsetHeight);
      canvas.setWidth(this.$refs.floorMapBlock.offsetWidth);
      var center = canvas.getCenter();
      console.log("====center =====", center);
      canvas.controlsAboveOverlay = true;
      // var url = 'https://cx-app-edwards-stage.uc.r.appspot.com/' + item.url;
      var url = mapInfo.url;
      console.log("==== url=====", url);

      fabric.Image.fromURL(url, (img) => {
        img.set({
          opacity: 1,
          scaleX: 1,
          scaleY: 1,
          top: center.top,
          left: center.left,
          originX: "center",
          originY: "center",
        });
        img.scale(that.scaleSize);
        // console.log(canvas.width/img.width);
        // canvas.add(img).setActiveObject(img);
        canvas
          .setBackgroundImage(img, canvas.requestRenderAll.bind(canvas))
          .renderAll();
      });
      console.log("====canvas.width =====", canvas.width);
      console.log("====canvas.height =====", canvas.height);
      const image = new window.Image();
      image.src = url;

      image.onload = function () {
        console.log("image----", image);
        that.imageW = image.width;
        that.imageH = image.height;
        console.log("image.width:", that.imageW); // 打印图片信息
        console.log("image.height:", that.imageH); // 打印图片信息
        console.log("==== canvas.width=====", canvas.width);
        console.log("==== canvasH=====", that.canvasH);
        let pointsArrXY = [];
        console.log("====item.points =====", item.points);
        item.points.forEach((ii) => {
          let newI = ii.split(",");
          if (newI != "") {
            let obj = {};
            obj.x =
              newI[0] * that.scaleSize +
              Number(canvas.width / 2) -
              Number((that.imageW * that.scaleSize) / 2);
            obj.y =
              newI[1] * that.scaleSize +
              Number(canvas.height / 2) -
              Number((that.imageH * that.scaleSize) / 2);
            pointsArrXY.push(obj);
          }
        });
        console.log("====pointsArrXY =====", pointsArrXY);
        let x = 0;
        let y = 0;
        pointsArrXY.map((item) => {
          console.log("====item =====", item);
          if (item.x != undefined && item.y != undefined) {
            x += Number(item.x);
            y += Number(item.y);
          }
        });
        let xCoordinate = x / 4;
        let yCoordinate = y / 4;
        console.log("==== xCoordinate=====", xCoordinate);
        console.log("==== yCoordinate=====", yCoordinate);

        let polygon = new fabric.Polygon(pointsArrXY, {
          fill: "#4164FD",
          hasControls: false,
          opacity: 0.5,
          hasBorders: true,
          id: "oldDesk",
          lockMovementX: true,
          lockMovementY: true,
        });
        canvas.add(polygon);
        canvas.renderAll();

        // var circle = new fabric.Circle({
        //   // top: circleTop, //距离画布上边的距离
        //   // left: circleLeft, //距离画布左侧的距离，单位是像素
        //   left: xCoordinate - 1, //距离画布左侧的距离，单位是像素
        //   top: yCoordinate - 1, //距离画布上边的距离
        //   radius: 1, //圆形半径
        //   fill: 'white', //填充的颜色
        //   hasControls: false,
        //   strokeWidth: 0.1,
        //   stroke: 'red'
        // });
        // canvas.add(circle).renderAll();

        var circle = new fabric.Circle({
          radius: 26,
          originX: "center",
          originY: "center",
          fill: "red", //填充的颜色
          hasControls: false,
          // strokeWidth: 0.1,
          // stroke: 'red',
          lockMovementX: true,
          lockMovementY: true,
          hasRotatingPoint: false,
          selectable: false,
        });

        let firstName = user.firstName[0];
        let lastName = user.lastName[0];
        let name = firstName + lastName;
        var text = new fabric.Text(name, {
          fontSize: 26,
          originX: "center",
          originY: "center",
          fill: "white",
        });

        var group = new fabric.Group([circle, text], {
          left: xCoordinate - 26,
          top: yCoordinate - 26,
          lockMovementX: true,
          lockMovementY: true,
        });
        canvas.add(group);
      };

      canvas.on("mouse:up", function (e) {
        canvas.selection = true;
      });

      //鼠标按下事件
      canvas.on("mouse:down", function (e) {
        console.log("==== down=====", e);
        this.panning = true;
        canvas.selection = false;
      });
      //鼠标抬起事件
      canvas.on("mouse:up", function (e) {
        this.panning = false;
      });
      // 移动画布事件
      canvas.on("mouse:move", function (e) {
        if (this.panning && e && e.e) {
          var delta = new fabric.Point(e.e.movementX, e.e.movementY);
          canvas.relativePan(delta);
        }
      });
      // 鼠标滚动画布放大缩小
      canvas.on("mouse:wheel", function (e) {
        console.log("====e =====", e);
        let container = document.getElementById("userLocationDialog");
        container.addEventListener("mousewheel", that.fn);
        var zoom = (event.deltaY > 0 ? -0.2 : 0.2) + canvas.getZoom();
        console.log("====zoom =====", zoom);
        zoom = Math.max(1, zoom); //最小为原来的1
        zoom = Math.min(50, zoom); //最大是原来的10倍
        var zoomPoint = new fabric.Point(event.offsetX, event.offsetY);
        canvas.zoomToPoint(zoomPoint, zoom);

        if (zoom > 3) {
          let group = canvas.getObjects("group")[0];
          let circle = group._objects[0];
          let text = group._objects[1];
          circle.radius = 2;
          // circle.strokeWidth = 0.1;
          text.fontSize = 2;
          canvas.renderAll();
        } else {
          let group = canvas.getObjects("group")[0];
          let circle = group._objects[0];
          let text = group._objects[1];
          circle.radius = 26;
          // circle.strokeWidth = 0.1;
          text.fontSize = 26;
          canvas.renderAll();
        }
      });

      // canvas.on("object：scaling", function (e) {
      //   console.log("==== =====",)
      //   var activeObject1 = e.target;
      //
      //   var elements = e.target._objects;
      //
      //   var count_elements = elements.length;
      //
      //   for (let i = 0; i < count_elements; i++) {
      //     var type = elements [i].typeCircle;
      //     console.log("====type =====", type)
      //   }
      // });
    },
    clickDeskList(data) {
      console.log("====list =====", data);
      let that = this;
      this.showUserLocation = true;
      let meetingId = localStorage.getItem("meetingIdMap");
      this.$axios({
        method: "GET",
        url: "m/spaces/map",
        params: {
          pageName: "map",
          mapId: data.floorId,
          meetingId: meetingId,
        },
      })
        .then((res) => {
          console.log("res:", res);
          let resources = res.data.data.resources;
          let mapInfo = res.data.data.mapInfo;
          let length = resources.length;
          for (let i = 0; i < length; i++) {
            let item = resources[i];
            if (item.resourceId == data.resourceId) {
              this.initCanvas(item, mapInfo, that.data);
              break;
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getAttributes() {
      // this.bus.$emit('loading', true);
      const _this = this;
      let url = "";
      url = "/m/directory/global/attributes?";
      if (_this.data.id != "") {
        url = url + "attendeeId=" + _this.data.id;
      }
      axios({
        method: "GET",
        url: url,
      })
        .then((res) => {
          // console.log(res,'sunsun');
          _this.attributes = res.data.data;
          // _this.isShowAttributes = true;
          // _this.bus.$emit('loading', false);
        })
        .catch((err) => {
          console.log(err);
          // _this.bus.$emit('loading', false);
        });
    },
    attritutesSearch(i) {
      this.$emit("attritutesSearch", i);
    },
    showAttributes() {
      if (this.isShowAttributes == true) {
        this.isShowAttributes = false;
      } else {
        this.isShowAttributes = true;
      }
    },
    updateFavorites() {
      this.bus.$emit("loading", true);
      const _this = this;
      let url = "";
      url = "/m/directory/global/favorite";
      console.log(_this.data.isFavorite, 99999999);
      if (_this.data.isFavorite == false) {
        axios({
          method: "patch",
          url: url,
          data: {
            attendeeId: _this.data.id,
          },
        })
          .then((res) => {
            console.log(res, "000");
            _this.data.isFavorite = true;
            _this.$emit("updateData", _this.data);
            _this.bus.$emit("loading", false);
          })
          .catch((err) => {
            console.log(err);
            _this.bus.$emit("loading", false);
          });
      } else {
        axios
          .delete(url, {
            data: {
              attendeeId: _this.data.id,
            },
          })
          .then((res) => {
            console.log(res, "000");
            _this.data.isFavorite = false;
            _this.$emit("updateData", _this.data);
            _this.bus.$emit("loading", false);
          })
          .catch((err) => {
            console.log(err);
            _this.bus.$emit("loading", false);
          });
      }
    },
  },
  created() {
    this.client = localStorage.getItem("client");

    if (localStorage.getItem("userLocationSharing") != undefined) {
      this.showDeskShareBtns = JSON.parse(
        localStorage.getItem("userLocationSharing")
      );
    }
    console.log("====showDeskShareBtns =====", this.showDeskShareBtns);
    console.log(this.data, "sunsunsun");
    // this.getAttributes();
  },
  mounted() {
    console.log("00000");
    this.firstInData = this.data;
    console.log(this.firstInData);
    if (Object.keys(this.data).length > 0) {
      if (this.data.image) {
        let src = this.data.image;
        if (src.startsWith("data:image")) {
          this.avatorImg = this.data.image;
        } else {
          this.avatorImg = "files/" + this.data.image;
        }
      }
    }
  },
};
</script>
<style lang="scss" scoped>
.staffInfo {
  height: 650px;
  background: #fff;

  .icon-chahao {
    position: absolute;
    top: 5px;
    right: 20px;
    color: #fff;
    cursor: pointer;
  }

  .title {
    //background: $main-blue;
    color: #ffffff;
    height: 120px;
    text-align: center;
    padding: 0 20px;
    display: flex;

    .staff {
      display: flex;
      align-items: center;

      .right {
        text-align: left;
        font-size: 1rem;
        padding-left: 1rem;

        .name {
          font-size: 1.2rem;
          font-weight: bold;
        }
      }
    }

    .deskShare {
      display: flex;
      align-items: center;
      margin-left: auto;
    }
  }

  .rightContent-content {
    height: 600px;
    overflow-y: auto;
    background: #fff;
    border: 1px solid #999;
    border-left: none;

    .staffContent {
      .datas {
        padding-top: 20px;
        padding-left: 30px;

        .oneDetail {
          margin-bottom: 1rem;

          .oneDetail-title {
            display: inline-block;
            width: 15%;
            min-width: 130px;
          }
        }
      }

      .staffContent-btn {
        text-align: center;
        margin: 1.5rem auto;
      }
    }

    //上司
    .leadership {
      .x-title-gray {
        background: #f3f3f3;
        height: 60px;
        line-height: 60px;
        color: #000;
        font-size: 1rem;
        font-weight: bold;
        padding-left: 30px;
      }

      .x-content {
        padding-left: 30px;
      }
    }

    //下属
    .subordinate {
      .x-title-gray {
        background: #f3f3f3;
        height: 60px;
        line-height: 60px;
        color: #000;
        font-size: 1rem;
        font-weight: bold;
        padding-left: 30px;
      }

      .x-content {
        padding-left: 30px;
      }
    }

    //摘要
    .brief {
      background: #fff;
      padding: 2rem;
    }
  }
}
</style>

<style lang="scss">
/*deskList弹窗*/
.deskListDialog {
  .v-card__text {
    padding: 0 !important;
  }

  .staffInfoDeskList:nth-child(odd) {
    background: #fff;
  }

  .staffInfoDeskList:nth-child(even) {
    background: #f3f3f3;
  }

  .staffInfoDeskList {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 5px;

    .information {
      display: flex;
      flex: 1;
      flex-direction: column;
      font-size: 15px;
    }

    i {
      font-size: 28px;
      padding: 8px;
    }
  }
}
.addToFavorites {
  p {
    margin: 0;
    font-size: 0.9rem;
  }
}
.attendeeItem {
  img {
    // width: 20px;
  }
  p {
    margin: 0;
    font-size: 1.1rem;
    font-weight: bold;
    line-height: 30px;
  }
}
.attributesSpan {
  display: inline-block;
  border: 1px solid #393933;
  border-radius: 20px;
  margin: 5px;
  padding: 2px 15px;
  color: #1473e6;
  margin-left: 0;
  font-size: 12px;
}
.triangle-up {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  display: inline-block;
  margin-left: 10px;
  border-bottom: 10px solid;
}
.triangle-color-ff0000 {
  border-bottom-color: #ff0000;
}
.triangle-color-a020f0 {
  border-bottom-color: #a020f0;
}
.triangle-color-1473e6 {
  border-bottom-color: #1473e6;
}
.triangle-color-fd9641 {
  border-bottom-color: #fd9641;
}
.seeingBooking {
  .x-btn {
    margin: 0 !important;
  }
}
.hook {
  width: 23px;
}
.gd-down {
  font-size: 22px;
  font-weight: 900;
}
.gd-down-180 {
  transform: rotate(360deg) !important;
}
.gd-down-detault {
  transform: rotate(-90deg);
}
</style>
